import { useMutation } from '@tanstack/react-query';
import { FileTransferFeature, fetchClient, queryClient } from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { mailKeys } from '../keys';

export function useUploadFile() {
  const { t } = useTranslation(['common']);
  const { toast } = useToast();

  return useMutation({
    mutationFn: (files: File[]) => {
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('overwrite', 'false');

      return fetchClient(
        `/api/file-transfer/${FileTransferFeature.MailAttachment}`,
        {
          method: 'POST',
          bodyType: 'formData',
          body: formData,
        },
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: mailKeys.all });
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}
