import {
  type InfiniteData,
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  type Communications_MailQuery,
  type InputMaybe,
  type MailFilter,
  type MailReadInput,
  type MailStarredInput,
  type SendMailInput,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useCallback } from 'react';
import {
  getInboxMailSummary,
  getInboxSendersSummary,
  getOutboxMailSummary,
  getOutboxRecipientsSummary,
  isMailUnread,
} from '../utils/get-summaries';
import { mailKeys } from './keys';

const DEFAULT_PAGINATION_LIMIT = 50;

const mails = graphql(/* GraphQL */ `
  query communications_mail($filter: MailFilter) {
    communications_mail(filter: $filter) {
      id
      rootMailId
      threadId
      subject
      body
      senderPartyId
      sender {
        partyId
        title {
          id
          nameTextId
          name
        }
        firstName
        lastName
        avatarUrl
        type
      }
      sentOn
      latestMessage
      canReply
      starred
      readOn
      recipients {
        id
        recipientPartyId
        recipientType
        recipient {
          partyId
          title {
            id
            nameTextId
            name
          }
          firstName
          lastName
          avatarUrl
          type
        }
      }
      labels {
        id
        name
        personPartyId
        colour
        custom
      }
      attachments {
        id
        url
      }
      threads {
        id
        rootMailId
        threadId
        subject
        body
        senderPartyId
        sentOn
        latestMessage
        canReply
        starred
        readOn
        sender {
          partyId
          title {
            id
            nameTextId
            name
          }
          firstName
          lastName
          avatarUrl
          type
        }
        recipients {
          id
          recipientPartyId
          recipientType
          recipient {
            partyId
            title {
              id
              nameTextId
              name
            }
            firstName
            lastName
            avatarUrl
            type
          }
        }
        labels {
          id
          name
          personPartyId
          colour
          custom
        }
        attachments {
          id
          url
        }
      }
    }
  }
`);

const sendMail = graphql(/* GraphQL */ `
  mutation communications_sendMail($input: SendMailInput) {
    communications_sendMail(input: $input) {
      id
    }
  }
`);

const starMail = graphql(/* GraphQL */ `
  mutation communications_starred($input: MailStarredInput) {
    communications_starred(input: $input)
  }
`);

const readMail = graphql(`
  mutation communications_read($input: MailReadInput) {
    communications_read(input: $input)
  }
`);

type MailPageParam = {
  lastId: number;
  lastMessage: string;
};

const mailListQuery = (
  labelId: number,
  profileId?: number | null,
  schoolMail = false,
) => {
  const filter = {
    pagination: { limit: DEFAULT_PAGINATION_LIMIT },
    partyId: profileId,
    labelId,
    schoolMail,
  };

  return {
    queryKey: mailKeys.filteredList(filter),
    initialPageParam: undefined,
    queryFn: async ({
      pageParam,
    }: QueryFunctionContext<QueryKey, MailPageParam | undefined>) =>
      gqlClient.request(mails, {
        filter: {
          ...filter,
          pagination: {
            ...filter.pagination,
            lastId: pageParam?.lastId,
            lastMessage: pageParam?.lastMessage,
          },
        },
      }),
  };
};

export function getMailList(
  labelId: number,
  profileId?: number | null,
  schoolMail = false,
) {
  return queryClient.fetchInfiniteQuery({
    ...mailListQuery(labelId, profileId, schoolMail),
  });
}

export function useMailList(
  labelId: number,
  profileId?: number | null,
  schoolMail = false,
) {
  return useInfiniteQuery({
    ...mailListQuery(labelId, profileId, schoolMail),
    getNextPageParam: ({ communications_mail }) => {
      const lastMail = communications_mail[communications_mail.length - 1];

      return communications_mail.length === DEFAULT_PAGINATION_LIMIT && lastMail
        ? ({
            lastId: lastMail.id,
            lastMessage: lastMail.latestMessage,
          } as MailPageParam)
        : undefined;
    },
    enabled: !!labelId,
    refetchInterval: 1000 * 60 * 2,
    select: useCallback(
      (data: InfiniteData<Communications_MailQuery>) => ({
        ...data,
        pages: data.pages.map(({ communications_mail }) =>
          communications_mail.map((mail) => ({
            ...mail,
            inboxSummary: getInboxMailSummary(mail, profileId),
            outboxSummary: getOutboxMailSummary(mail, profileId),
            inboxSenderSummary: getInboxSendersSummary(mail, profileId),
            outboxRecipientSummary: getOutboxRecipientsSummary(mail, profileId),
            isMailUnread: isMailUnread(mail, profileId),
          })),
        ),
      }),
      [profileId],
    ),
  });
}

const mailQuery = (filter: MailFilter) => ({
  queryKey: mailKeys.mail(filter),
  queryFn: async () => gqlClient.request(mails, { filter }),
});

export function getMail(mailId: number, schoolMail = false) {
  return queryClient.fetchQuery(
    mailQuery({
      id: mailId,
      pagination: { limit: 1 },
      schoolMail,
    }),
  );
}

export function useMail(mailId: number, schoolMail = false) {
  return useQuery({
    ...mailQuery({
      id: mailId,
      pagination: { limit: 1 },
      schoolMail,
    }),
    staleTime: 0,
    select: ({ communications_mail }) => communications_mail[0],
  });
}

export function useSendMail() {
  const { toast } = useToast();
  const { t } = useTranslation(['mail']);

  return useMutation({
    mutationFn: async (input: SendMailInput) =>
      gqlClient.request(sendMail, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: mailKeys.all });
      toast(t('mail:mailSentSuccess'));
    },
    onError: () => {
      toast(t('mail:mailSentError', { variant: 'error' }));
    },
  });
}

export function useStarMail() {
  return useMutation({
    mutationFn: async (input: InputMaybe<MailStarredInput>) =>
      gqlClient.request(starMail, { input }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: mailKeys.all });
    },
  });
}

export function useReadMail() {
  return useMutation({
    mutationFn: async (input: MailReadInput) =>
      gqlClient.request(readMail, { input }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: mailKeys.unreadCounts() });
      queryClient.invalidateQueries({ queryKey: mailKeys.lists() });
    },
  });
}

export type ReturnTypeUseMailList = UseQueryReturnType<
  typeof useMailList
>['pages'][number][number];

export type ReturnTypeUseMail = UseQueryReturnType<typeof useMail>;
