import {
  type Label,
  LabelType,
  type RecipientSearchType,
  usePermissions,
  useUser,
} from '@tyro/api';
import {
  type UseDisclosureReturn,
  useDebouncedValue,
  useDisclosure,
} from '@tyro/core';
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import { useUnreadCount } from '../api/labels';
import { resolveMailRecipients } from '../api/mail-recipients';
import MailCompose, {
  type ComposeMailFormValues,
  MailTypeMessage,
} from '../components/common/compose';
import {
  type MailRecipientType,
  SelectMailRecipientTypeModal,
} from '../components/common/select-mail-recipient-type-modal';
import { getLabelId } from '../utils/labels';

export type MailSettingsContextValue = {
  sidebarDisclosure: UseDisclosureReturn;
  composeDisclosure: UseDisclosureReturn;
  activeProfileId: number;
  composeEmail: (defaultValue: Partial<ComposeMailFormValues>) => void;
  sendMailToParties: (
    partyIds: number[],
    possibleMailRecipientTypes: MailRecipientType[],
  ) => void;
  hasPermissionForSchoolInbox: boolean;
  isSchoolMail: boolean;
  partyUnreadCount: number;
  schoolUnreadCount: number;
  getUnreadCount: (label: Pick<Label, 'type' | 'id'>) => number;
};

const MailSettingsContext = createContext<MailSettingsContextValue | undefined>(
  undefined,
);

export function MailSettingsProvider({ children }: { children: ReactNode }) {
  const [defaultComposeValue, setDefaultComposeValue] = useState<
    Partial<ComposeMailFormValues>
  >({});
  const sidebarDisclosure = useDisclosure({ defaultIsOpen: false });
  const composeDisclosure = useDisclosure({ defaultIsOpen: false });
  const location = useLocation();
  const {
    value: selectMailRecipientSettings,
    debouncedValue: debouncedSelectMailRecipientSettings,
    setValue: setSelectMailRecipientSettings,
  } = useDebouncedValue<
    | {
        partyIds: number[];
        possibleRecipientTypes: MailRecipientType[];
      }
    | undefined
  >({ defaultValue: undefined });
  const { activeProfile } = useUser();
  const { hasPermission } = usePermissions();
  const activeProfileId = location.pathname.includes('mail/school')
    ? -1
    : activeProfile?.partyId ?? 0;

  const { data: unreadCountData } = useUnreadCount({
    personPartyId: activeProfile?.partyId ?? 0,
  });

  const hasPermissionForSchoolInbox = hasPermission(
    'ps:1:communications:school_mailbox',
  );

  const composeEmail = (defaultValue: Partial<ComposeMailFormValues>) => {
    setDefaultComposeValue(defaultValue);
    composeDisclosure.onOpen();
  };

  const composeEmailToParties = async (
    partyIds: number[],
    possibleMailRecipientTypes: RecipientSearchType[],
  ) => {
    const { communications_recipients: communicationsRecipients } =
      await resolveMailRecipients({
        partyIds,
        recipientType: possibleMailRecipientTypes,
      });

    if (selectMailRecipientSettings) {
      setSelectMailRecipientSettings(undefined);
    }
    composeEmail({
      canReply: false,
      typeMessage: MailTypeMessage.BROADCAST,
      toRecipients: communicationsRecipients,
    });
  };

  const sendMailToParties = (
    partyIds: number[],
    possibleMailRecipientTypes: MailRecipientType[],
  ) => {
    if (
      process.env.NODE_ENV !== 'production' &&
      !(partyIds?.length > 0 && possibleMailRecipientTypes?.length > 0)
    ) {
      throw new Error(
        'partyIds and possibleMailRecipientTypes must not be empty when calling sendMailToParties',
      );
    }

    if (possibleMailRecipientTypes.length === 1) {
      composeEmailToParties(partyIds, [possibleMailRecipientTypes[0].type]);
    } else {
      setSelectMailRecipientSettings({
        partyIds,
        possibleRecipientTypes: possibleMailRecipientTypes,
      });
    }
  };

  useEffect(() => {
    if (
      hasPermissionForSchoolInbox &&
      location.pathname.includes('mail/school')
    ) {
      localStorage.setItem('tyro:preferred-mail', 'school');
    } else if (location.pathname.includes('mail')) {
      localStorage.removeItem('tyro:preferred-mail');
    }
  }, [location]);

  const value = useMemo<MailSettingsContextValue>(
    () => ({
      sidebarDisclosure,
      composeDisclosure,
      composeEmail,
      activeProfileId,
      sendMailToParties,
      hasPermissionForSchoolInbox,
      isSchoolMail: activeProfileId === -1,
      getUnreadCount: (label) => {
        const labelId = getLabelId(label);
        return unreadCountData?.get(`${activeProfileId}-${labelId}`) ?? 0;
      },
      partyUnreadCount:
        unreadCountData?.get(`${activeProfile?.partyId ?? 0}-inbox`) ?? 0,
      schoolUnreadCount: unreadCountData?.get(`${-1}-inbox`) ?? 0,
    }),
    [
      sidebarDisclosure,
      composeDisclosure,
      composeEmail,
      activeProfileId,
      sendMailToParties,
      hasPermissionForSchoolInbox,
      unreadCountData,
    ],
  );

  useEffect(() => {
    if (!composeDisclosure.isOpen) {
      sidebarDisclosure.onClose();
    }
  }, [composeDisclosure.isOpen]);

  return (
    <MailSettingsContext.Provider value={value}>
      {children}
      {composeDisclosure.isOpen && (
        <MailCompose
          onCloseCompose={composeDisclosure.onClose}
          defaultValues={defaultComposeValue}
          activeProfileId={activeProfileId}
          hasPermissionForSchoolInbox={hasPermissionForSchoolInbox}
        />
      )}
      <SelectMailRecipientTypeModal
        isOpen={!!selectMailRecipientSettings}
        onClose={() => setSelectMailRecipientSettings(undefined)}
        onSubmit={composeEmailToParties}
        settings={
          selectMailRecipientSettings || debouncedSelectMailRecipientSettings
        }
      />
    </MailSettingsContext.Provider>
  );
}

export function useMailSettings() {
  const context = useContext(MailSettingsContext);
  if (context === undefined) {
    throw new Error(
      'useMailSettings must be used within a MailSettingsContext',
    );
  }
  return context;
}
