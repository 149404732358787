import { LoadingButton } from '@mui/lab';
import { FormControlLabel, Stack, Switch } from '@mui/material';
import type { Editor } from '@tiptap/react';
import { usePermissions } from '@tyro/api';
import { useTranslation } from '@tyro/i18n';
import { AttachFiles } from './attach-files';
import type { AttachedFilesListProps } from './attach-files/list';
import { InsertLinkButton } from './insert-link-button';
import { MailEditorTextPopover } from './text-popover';

interface MailEditorToolbarProps {
  editor: Editor | null;
  onSend: () => void;
  isSending: boolean;
  setAttachFiles: AttachedFilesListProps['setAttachFiles'];
  canReplyValue?: boolean;
  onCanReplyChange?: (canReply: boolean) => void;
}

export function MailEditorToolbar({
  editor,
  onSend,
  isSending,
  setAttachFiles,
  canReplyValue,
  onCanReplyChange,
}: MailEditorToolbarProps) {
  const { t } = useTranslation(['common', 'mail']);
  const { hasPermission } = usePermissions();

  return (
    <Stack
      direction="row"
      py={2}
      pl={3}
      pr={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <LoadingButton variant="contained" onClick={onSend} loading={isSending}>
          {t('common:actions.send')}
        </LoadingButton>

        <Stack direction="row" alignItems="center">
          {hasPermission('ps:1:documents:write_mail_attachments') && (
            <AttachFiles setAttachFiles={setAttachFiles} />
          )}
          <MailEditorTextPopover editor={editor} />
          <InsertLinkButton editor={editor} />
        </Stack>
      </Stack>
      {onCanReplyChange && (
        <FormControlLabel
          control={<Switch value={canReplyValue} />}
          label={t('mail:canReply')}
          checked={canReplyValue}
          onChange={(_e, checked) => onCanReplyChange(checked)}
        />
      )}
    </Stack>
  );
}
